import React, {Component} from 'react'

import LangBar from '../LangBar'

class Footer extends Component {
  render() {
    return <LangBar/>
  }
}

export default Footer;
